import { useTranslation } from '@pancakeswap/localization'
import React from 'react'
import MetaTags from 'react-meta-tags'
import styled from 'styled-components'
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useRouter } from 'next/router'
import { Flex, Box } from '@pancakeswap/uikit'
import { MENU_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config'

const PageMeta = () => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { pathname } = useRouter()
  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  const pageTitle = title

  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
    </MetaTags>
  )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <Wrapper>
      <PageMeta />
      <Bg>
        <img className="background-origin" src="/images/pibridge/background.png" alt="background" />
      </Bg>
      <Row>
        <ColImageLeft>
          <WrapImg>
            <ImgIconLeft src="/images/pibridge/stone_left.png" alt="background" />
          </WrapImg>
        </ColImageLeft>
        <ColImageRight>
          <WrapImg>
            <ImgIconRight src="/images/pibridge/stone_right.png" alt="background" />
          </WrapImg>
        </ColImageRight>
      </Row>
      <CsContainer>{children}</CsContainer>
    </Wrapper>
  )
}

export default PageFullWidth

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-top: -${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.background};
  @media screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
`
const Row = styled.div`
  height: 100%;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  z-index: -1;
  height: 100%;
  opacity: 0.7;
  overflow: hidden;
  .background-origin {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 1280px) {
    display: none;
  }
`
const ColImageLeft = styled(Flex)`
  position: absolute;
  left: 38px;
  top: 303px;
  @media screen and (min-width: 2560px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (min-width: 2000px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (max-width: 1400px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (max-width: 1024px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (max-width: 920px) {
    left: 20px;
    top: 303px;
  }
  @media screen and (max-width: 768px) {
    left: 15px;
    top: 303px;
  }
  @media screen and (max-width: 600px) {
    left: 10px;
    top: 303px;
  }
  @media screen and (max-width: 500px) {
    top: 187px;
  }
  @media screen and (max-width: 320px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (min-width: 2560px) {
    left: 38px;
    top: 303px;
  }
  @media screen and (min-width: 2000px) {
    left: 38px;
    top: 303px;
  }
`
const ColImageRight = styled(Flex)`
  position: absolute;
  right: -35px;
  top: 474px;
  @media screen and (min-width: 2560px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (min-width: 2000px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 1400px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 1024px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 920px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 768px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 600px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (max-width: 500px) {
    top: 487px;
  }
  @media screen and (max-width: 320px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (min-width: 2560px) {
    right: -35px;
    top: 420px;
  }
  @media screen and (min-width: 2000px) {
    right: -35px;
    top: 420px;
  }
`
const WrapImg = styled.div``

const ImgIconRight = styled.img`
  width: 620px;
  @media screen and (max-width: 1336px) {
    width: 555px;
  }
  @media screen and (max-width: 1024px) {
    width: 450px;
  }
  @media screen and (max-width: 920px) {
    width: 330px;
  }
  @media screen and (max-width: 600px) {
    width: 231px;
  }
  @media screen and (max-width: 360px) {
    width: 180px;
  }
`
const ImgIconLeft = styled.img`
  width: 301px;
  @media screen and (max-width: 1336px) {
    width: 280px;
  }
  @media screen and (max-width: 1024px) {
    width: 200px;
  }
  @media screen and (max-width: 920px) {
    width: 180px;
  }
  @media screen and (max-width: 600px) {
    width: 160px;
  }
  @media screen and (max-width: 360px) {
    width: 140px;
  }
`
const CsContainer = styled(Box)`
  max-width: 1320px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
  z-index: 101;
  margin-top: ${MENU_HEIGHT}px;
  @media screen and (min-width: 769px) and (max-width: 1280px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  @media screen and (min-width: 601px) and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
